@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import './assets/fonts/fonts.css';

/* input[type='file'] {
  -webkit-appearance: none;
  text-align: left;
  border: 2px dashed #24abdf;
  border-radius: '6px';
  -webkit-rtl-ordering: left;
}
input[type='file']::-webkit-file-upload-button {
  -webkit-appearance: none;
  float: right;
  border: 1px solid #aaaaaa;
  border-radius: 6px;
  padding: 5px;
  top: 1188px;
  left: 361px;
  width: 176px;
  height: 44px;
  margin-top: -10px;
  margin-right: -10px;
  font-family: 'Poppins';
  color: #ffffff;
  background: #112b3d 0% 0% no-repeat padding-box;
} */
body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, #25aae1 7%, #18bfc5 57%, #0dd1ae 97%);

  border-radius: 16px;
}
.bn-onboard-modal {
  font-family: Roboto;
  z-index: 4;
}

@keyframes rotateGradient {
  /* left to right */
  0% {
    background: linear-gradient(90deg, #007bff 0%, #10c6ff 100%);
  }
  5% {
    background: linear-gradient(95deg, #007bff 0%, #10c6ff 100%);
  }
  10% {
    background: linear-gradient(100deg, #007bff 0%, #10c6ff 100%);
  }
  15% {
    background: linear-gradient(110deg, #007bff 0%, #10c6ff 100%);
  }
  20% {
    background: linear-gradient(120deg, #007bff 0%, #10c6ff 100%);
  }
  25% {
    background: linear-gradient(130deg, #007bff 0%, #10c6ff 100%);
  }
  30% {
    background: linear-gradient(140deg, #007bff 0%, #10c6ff 100%);
  }
  35% {
    background: linear-gradient(150deg, #007bff 0%, #10c6ff 100%);
  }
  40% {
    background: linear-gradient(160deg, #007bff 0%, #10c6ff 100%);
  }
  45% {
    background: linear-gradient(170deg, #007bff 0%, #10c6ff 100%);
  }
  /* top to bottom */
  50% {
    background: linear-gradient(180deg, #007bff 0%, #10c6ff 100%);
  }
  55% {
    background: linear-gradient(190deg, #007bff 0%, #10c6ff 100%);
  }
  60% {
    background: linear-gradient(200deg, #007bff 0%, #10c6ff 100%);
  }
  65% {
    background: linear-gradient(210deg, #007bff 0%, #10c6ff 100%);
  }
  70% {
    background: linear-gradient(220deg, #007bff 0%, #10c6ff 100%);
  }
  75% {
    background: linear-gradient(230deg, #007bff 0%, #10c6ff 100%);
  }
  80% {
    background: linear-gradient(240deg, #007bff 0%, #10c6ff 100%);
  }
  85% {
    background: linear-gradient(250deg, #007bff 0%, #10c6ff 100%);
  }
  90% {
    background: linear-gradient(260deg, #007bff 0%, #10c6ff 100%);
  }
  95% {
    background: linear-gradient(265deg, #007bff 0%, #10c6ff 100%);
  }
  /* right to left */
  100% {
    background: linear-gradient(270deg, #007bff 0%, #10c6ff 100%);
  }
}

@keyframes rotateGradientReverse {
  /* right to left */
  0% {
    background: linear-gradient(270deg, #007bff 0%, #10c6ff 100%);
  }
  5% {
    background: linear-gradient(265deg, #007bff 0%, #10c6ff 100%);
  }
  10% {
    background: linear-gradient(260deg, #007bff 0%, #10c6ff 100%);
  }
  15% {
    background: linear-gradient(250deg, #007bff 0%, #10c6ff 100%);
  }
  20% {
    background: linear-gradient(240deg, #007bff 0%, #10c6ff 100%);
  }
  25% {
    background: linear-gradient(230deg, #007bff 0%, #10c6ff 100%);
  }
  30% {
    background: linear-gradient(220deg, #007bff 0%, #10c6ff 100%);
  }
  35% {
    background: linear-gradient(210deg, #007bff 0%, #10c6ff 100%);
  }
  40% {
    background: linear-gradient(200deg, #007bff 0%, #10c6ff 100%);
  }
  45% {
    background: linear-gradient(190deg, #007bff 0%, #10c6ff 100%);
  }
  /* bottom to top */
  50% {
    background: linear-gradient(180deg, #007bff 0%, #10c6ff 100%);
  }
  55% {
    background: linear-gradient(170deg, #007bff 0%, #10c6ff 100%);
  }
  60% {
    background: linear-gradient(160deg, #007bff 0%, #10c6ff 100%);
  }
  65% {
    background: linear-gradient(150deg, #007bff 0%, #10c6ff 100%);
  }
  70% {
    background: linear-gradient(140deg, #007bff 0%, #10c6ff 100%);
  }
  75% {
    background: linear-gradient(130deg, #007bff 0%, #10c6ff 100%);
  }
  80% {
    background: linear-gradient(120deg, #007bff 0%, #10c6ff 100%);
  }
  85% {
    background: linear-gradient(110deg, #007bff 0%, #10c6ff 100%);
  }
  90% {
    background: linear-gradient(100deg, #007bff 0%, #10c6ff 100%);
  }
  95% {
    background: linear-gradient(95deg, #007bff 0%, #10c6ff 100%);
  }
  /* left to right */
  100% {
    background: linear-gradient(90deg, #007bff 0%, #10c6ff 100%);
  }
}
