@font-face {
  font-family: 'BeVietnamPro';
  src: url('./BeVietnamPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Hind';
  src: url('./Hind-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppings';
  src: url('./Poppins.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cy Extrabold';
  src: url('./Cy-ExtraBold-iF6673e73727887.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cy Semibold';
  src: url('./Cy-SemiBold-iF6673e736f094d.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
